<template>
  <div>
    <v-layout
      wrap
      justify-center
      :style="{
        'padding-top':
          $vuetify.breakpoint.name == 'xs'
            ? '0px'
            : $vuetify.breakpoint.name == 'sm'
            ? '0px'
            : $vuetify.breakpoint.name == 'md'
            ? '700px'
            : $vuetify.breakpoint.name == 'lg'
            ? '580px'
            : '550px',
      }"
    >
      <v-flex xs12>
        <v-card elevation="10">
          <v-layout wrap v-if="storage.expertise">
            <v-flex xs12 sm12 md6 style="background-color: #3cb043">
              <v-layout wrap justify-end>
                <v-flex xs12 sm9 py-10>
                  <v-img
                    :src="mediaURL + storage.expertise.image"
                    :lazy-src="mediaURL + storage.expertise.image"
                    :alt="storage.expertise.image"
                    eager
                  >
                    <template v-slot:placeholder>
                      <ImageLoader /> </template
                  ></v-img>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm12 md5 align-self-center pa-10>
              <v-layout wrap v-if="storage.expertise">
                <v-flex xs12 text-left>
                  <span
                    style="
                      font-family: poppinssemibold;
                      color: #3cb043;
                      font-size: 14px;
                      text-transform: uppercase;
                      letter-spacing: 1px;
                    "
                    :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                    }"
                    >Expertise</span
                  >
                </v-flex>
                <v-flex xs12 sm12 md9 pt-2 text-left>
                  <span
                    style="
                      font-family: poppinsbold;
                      color: #000000;
                      font-size: 24px;
                      letter-spacing: 1px;
                    "
                    :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '28px' : '24px',
                    }"
                  >
                    {{ storage.expertise.title }}
                  </span>
                </v-flex>
                <v-flex xs12 sm12 md12 pt-4 text-left>
                  <span
                    style="
                      font-family: poppinsregular;
                      color: #000000;
                      font-size: 14px;
                      letter-spacing: 1px;
                      text-align: justify;
                    "
                    :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                    }"
                    v-html="storage.expertise.description"
                  >
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs1 pt-10 class="hidden-sm-and-down">
              <v-img
                contain
                height="60px"
                src="./../../assets/images/aboutdec.png"
              >
              </v-img>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
    };
  },
};
</script>