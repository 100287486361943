<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-layout wrap>
      <v-flex xs12 align-self-center>
        <div class="Headd" v-if="about">
          <v-img
            height="650px"
            :src="mediaURL + about.banner"
            :lazy-src="mediaURL + about.banner"
            :alt="about.banner"
            eager
            ><template v-slot:placeholder>
              <ImageLoader />
            </template>
            <v-layout wrap fill-height align-content-center>
              <v-flex xs12 align-self-center>
                <v-layout wrap justify-center>
                  <v-flex xs12>
                    <span
                      style="
                        font-family: poppinsbold;
                        color: white;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                      "
                      :style="{
                        'font-size':
                          $vuetify.breakpoint.name == 'xl' ? '32px' : '28px',
                      }"
                      >About</span
                    >
                  </v-flex>
                  <v-flex xs12 pt-5>
                    <span
                      style="
                        font-family: poppinsmedium;
                        color: white;
                        font-size: 20px;
                        letter-spacing: 1px;
                      "
                      :style="{
                        'font-size':
                          $vuetify.breakpoint.name == 'xl' ? '24px' : '20px',
                      }"
                    >
                      {{ about.title }}
                    </span>
                  </v-flex>
                  <v-flex xs12 sm10 md7 pt-4>
                    <span
                      style="
                        font-family: poppinsregular;
                        color: white;
                        font-size: 16px;
                        letter-spacing: 1px;
                      "
                      :style="{
                        'font-size':
                          $vuetify.breakpoint.name == 'xl' ? '20px' : '16px',
                      }"
                      v-html="about.description"
                    >
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-img>
        </div>
        <div class="Taill hidden-sm-and-down" v-if="about.aboutFounder">
          <v-layout wrap justify-center class="clash">
            <v-flex xs12 sm12 md11 lg10 py-5 align-self-center>
              <v-card class="boom" elevation="24">
                <v-layout wrap justify-center>
                  <v-flex xs12 sm12 md8 lg8 xl10 pa-10 align-self-center>
                    <v-layout wrap v-if="about.aboutFounder">
                      <v-flex xs12 text-center>
                        <span
                          style="
                            font-family: poppinssemibold;
                            color: #3cb043;
                            font-size: 14px;
                            text-transform: uppercase;
                            letter-spacing: 1px;
                          "
                          :style="{
                            'font-size':
                              $vuetify.breakpoint.name == 'xl'
                                ? '18px'
                                : '14px',
                          }"
                          >OUR FOUNDER</span
                        >
                      </v-flex>
                      <v-flex xs12 pt-2 text-center>
                        <span
                          style="
                            font-family: poppinsbold;
                            color: #000000;
                            font-size: 24px;
                            letter-spacing: 1px;
                          "
                          :style="{
                            'font-size':
                              $vuetify.breakpoint.name == 'xl'
                                ? '28px'
                                : '24px',
                          }"
                        >
                          {{ about.aboutFounder.title }}
                        </span>
                      </v-flex>
                      <v-flex xs12 sm12 md12 pt-4 text-left>
                        <span
                          style="
                            font-family: poppinsregular;
                            color: #000000;
                            font-size: 14px;
                            letter-spacing: 1px;
                            text-align: justify;
                          "
                          :style="{
                            'font-size':
                              $vuetify.breakpoint.name == 'xl'
                                ? '18px'
                                : '14px',
                          }"
                          v-html="about.aboutFounder.description"
                        >
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm10 md4 lg4 xl2 v-if="about.aboutFounder">
                    <v-layout wrap>
                      <v-flex xs12 sm12 md12>
                        <v-img
                          :style="{
                            height:
                              $vuetify.breakpoint.name == 'xs'
                                ? '100%'
                                : $vuetify.breakpoint.name == 'sm'
                                ? '100%'
                                : $vuetify.breakpoint.name == 'md'
                                ? '680px'
                                : $vuetify.breakpoint.name == 'lg'
                                ? '590px'
                                : '550px',
                          }"
                          :src="mediaURL + about.aboutFounder.image"
                          :lazy-src="mediaURL + about.aboutFounder.image"
                          :alt="about.aboutFounder.image"
                          eager
                          ><template v-slot:placeholder>
                            <ImageLoader />
                          </template>
                        </v-img>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </div>
        <div>
          <v-layout wrap justify-center class="hidden-md-and-up">
            <v-flex xs12 sm12 py-5 align-self-center>
              <v-card elevation="24">
                <v-layout wrap justify-center>
                  <v-flex xs12 sm12 md8 lg8 xl10 pa-10 align-self-center>
                    <v-layout wrap v-if="about.aboutFounder">
                      <v-flex xs12 text-center>
                        <span
                          style="
                            font-family: poppinssemibold;
                            color: #3cb043;
                            font-size: 14px;
                            text-transform: uppercase;
                            letter-spacing: 1px;
                          "
                          :style="{
                            'font-size':
                              $vuetify.breakpoint.name == 'xl'
                                ? '18px'
                                : '14px',
                          }"
                          >OUR FOUNDER</span
                        >
                      </v-flex>
                      <v-flex xs12 pt-2 text-center>
                        <span
                          style="
                            font-family: poppinsbold;
                            color: #000000;
                            font-size: 24px;
                            letter-spacing: 1px;
                          "
                          :style="{
                            'font-size':
                              $vuetify.breakpoint.name == 'xl'
                                ? '28px'
                                : '24px',
                          }"
                        >
                          {{ about.aboutFounder.title }}
                        </span>
                      </v-flex>
                      <v-flex xs12 sm12 md12 pt-4 text-left>
                        <span
                          style="
                            font-family: poppinsregular;
                            color: #000000;
                            font-size: 14px;
                            letter-spacing: 1px;
                            text-align: justify;
                          "
                          :style="{
                            'font-size':
                              $vuetify.breakpoint.name == 'xl'
                                ? '18px'
                                : '14px',
                          }"
                          v-html="about.aboutFounder.description"
                        >
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm10 md4 lg4 xl2 v-if="about.aboutFounder">
                    <v-layout wrap>
                      <v-flex xs12 sm12 md12>
                        <v-img
                          :style="{
                            height:
                              $vuetify.breakpoint.name == 'xs'
                                ? '100%'
                                : $vuetify.breakpoint.name == 'sm'
                                ? '100%'
                                : $vuetify.breakpoint.name == 'md'
                                ? '680px'
                                : $vuetify.breakpoint.name == 'lg'
                                ? '100%'
                                : '550px',
                          }"
                          :src="mediaURL + about.aboutFounder.image"
                          ><template v-slot:placeholder>
                            <ImageLoader />
                          </template>
                        </v-img>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <Section2 :storage="about" />
          <Section3 :storage="about" />
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import Section2 from "./section2";
import Section3 from "./section3";
export default {
  components: {
    Section2,
    Section3,
    // Section4,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      user: {},
      about: [],
    };
  },
  beforeMount() {
    this.getData();
  },

  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/abouUs",
        method: "GET",
        // headers: {
        //   token: localStorage.getItem("token"),
        // },
      })
        .then((response) => {
          this.appLoading = false;
          this.about = response.data.data;
          //           setTimeout(function(){
          //    window.location.reload();
          // }, 1000);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.boom {
  background-color: #fff;
  z-index: 2px;
}

.clash {
  z-index: 1px;
  /* padding-bottom: 250px; */
  position: relative;
}

.Headd {
  position: relative;
}

.Taill {
  position: absolute;
  top: 650px;
  z-index: 3;
  right: 0;
  left: 0;
  display: grid;
}
</style>