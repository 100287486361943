var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-img',{attrs:{"src":require("./../../assets/images/aboutBack.png")}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pt-8":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md11":"","lg10":"","py-16":"","align-self-center":""}},[_c('v-card',{attrs:{"elevation":"24"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md8":"","lg7":"","pa-10":"","align-self-center":""}},[(_vm.storage.globalReach)?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":""}},[_c('span',{staticStyle:{"font-family":"poppinssemibold","color":"#3cb043","font-size":"14px","text-transform":"uppercase","letter-spacing":"1px"},style:({
                      'font-size':
                        _vm.$vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                    })},[_vm._v(" Global Reach")])]),_c('v-flex',{attrs:{"xs12":"","sm12":"","md8":"","pt-2":"","text-left":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"#000000","font-size":"24px","letter-spacing":"1px"},style:({
                      'font-size':
                        _vm.$vuetify.breakpoint.name == 'xl' ? '28px' : '24px',
                    })},[_vm._v(" "+_vm._s(_vm.storage.globalReach.title)+" ")])]),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","pt-4":"","text-left":""}},[_c('span',{staticStyle:{"font-family":"poppinsregular","color":"#000000","font-size":"14px","letter-spacing":"1px","text-align":"justify"},style:({
                      'font-size':
                        _vm.$vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                    }),domProps:{"innerHTML":_vm._s(_vm.storage.globalReach.description)}})])],1):_vm._e()],1),(_vm.storage.globalReach)?_c('v-flex',{attrs:{"xs12":"","sm12":"","md4":"","lg5":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-img',{attrs:{"height":"550px","src":_vm.mediaURL + _vm.storage.globalReach.image,"lazy-src":_vm.mediaURL + _vm.storage.globalReach.image,"alt":_vm.storage.globalReach.image,"eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }