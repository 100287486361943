var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{style:({
      'padding-top':
        _vm.$vuetify.breakpoint.name == 'xs'
          ? '0px'
          : _vm.$vuetify.breakpoint.name == 'sm'
          ? '0px'
          : _vm.$vuetify.breakpoint.name == 'md'
          ? '700px'
          : _vm.$vuetify.breakpoint.name == 'lg'
          ? '580px'
          : '550px',
    }),attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{attrs:{"elevation":"10"}},[(_vm.storage.expertise)?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticStyle:{"background-color":"#3cb043"},attrs:{"xs12":"","sm12":"","md6":""}},[_c('v-layout',{attrs:{"wrap":"","justify-end":""}},[_c('v-flex',{attrs:{"xs12":"","sm9":"","py-10":""}},[_c('v-img',{attrs:{"src":_vm.mediaURL + _vm.storage.expertise.image,"lazy-src":_vm.mediaURL + _vm.storage.expertise.image,"alt":_vm.storage.expertise.image,"eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md5":"","align-self-center":"","pa-10":""}},[(_vm.storage.expertise)?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":""}},[_c('span',{staticStyle:{"font-family":"poppinssemibold","color":"#3cb043","font-size":"14px","text-transform":"uppercase","letter-spacing":"1px"},style:({
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                  })},[_vm._v("Expertise")])]),_c('v-flex',{attrs:{"xs12":"","sm12":"","md9":"","pt-2":"","text-left":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"#000000","font-size":"24px","letter-spacing":"1px"},style:({
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xl' ? '28px' : '24px',
                  })},[_vm._v(" "+_vm._s(_vm.storage.expertise.title)+" ")])]),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","pt-4":"","text-left":""}},[_c('span',{staticStyle:{"font-family":"poppinsregular","color":"#000000","font-size":"14px","letter-spacing":"1px","text-align":"justify"},style:({
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                  }),domProps:{"innerHTML":_vm._s(_vm.storage.expertise.description)}})])],1):_vm._e()],1),_c('v-flex',{staticClass:"hidden-sm-and-down",attrs:{"xs1":"","pt-10":""}},[_c('v-img',{attrs:{"contain":"","height":"60px","src":require("./../../assets/images/aboutdec.png")}})],1)],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }