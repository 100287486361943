<template>
  <div>
    <v-img src="./../../assets/images/aboutBack.png">
      <v-layout wrap justify-center pt-8>
        <v-flex xs12 sm12 md11 lg10 py-16 align-self-center>
          <v-card elevation="24">
            <v-layout wrap justify-center>
              <v-flex xs12 sm12 md8 lg7 pa-10 align-self-center>
                <v-layout wrap v-if="storage.globalReach">
                  <v-flex xs12 text-left>
                    <span
                      style="
                        font-family: poppinssemibold;
                        color: #3cb043;
                        font-size: 14px;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                      "
                      :style="{
                        'font-size':
                          $vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                      }"
                    >
                      Global Reach</span
                    >
                  </v-flex>
                  <v-flex xs12 sm12 md8 pt-2 text-left>
                    <span
                      style="
                        font-family: poppinsbold;
                        color: #000000;
                        font-size: 24px;
                        letter-spacing: 1px;
                      "
                      :style="{
                        'font-size':
                          $vuetify.breakpoint.name == 'xl' ? '28px' : '24px',
                      }"
                    >
                      {{ storage.globalReach.title }}
                    </span>
                  </v-flex>
                  <v-flex xs12 sm12 md12 pt-4 text-left>
                    <span
                      style="
                        font-family: poppinsregular;
                        color: #000000;
                        font-size: 14px;
                        letter-spacing: 1px;
                        text-align: justify;
                      "
                      :style="{
                        'font-size':
                          $vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                      }"
                      v-html="storage.globalReach.description"
                    >
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm12 md4 lg5 v-if="storage.globalReach">
                <v-layout wrap>
                  <v-flex xs12>
                    <v-img
                      height="550px"
                      :src="mediaURL + storage.globalReach.image"
                      :lazy-src="mediaURL + storage.globalReach.image"
                      :alt="storage.globalReach.image"
                      eager
                    >
                      <template v-slot:placeholder>
                        <ImageLoader />
                      </template>
                    </v-img>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-img>
  </div>
</template>
<script>
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
    };
  },
};
</script>