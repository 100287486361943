var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageLoader',{attrs:{"storage":_vm.appLoading}}),(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","align-self-center":""}},[(_vm.about)?_c('div',{staticClass:"Headd"},[_c('v-img',{attrs:{"height":"650px","src":_vm.mediaURL + _vm.about.banner,"lazy-src":_vm.mediaURL + _vm.about.banner,"alt":_vm.about.banner,"eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)},[_c('v-layout',{attrs:{"wrap":"","fill-height":"","align-content-center":""}},[_c('v-flex',{attrs:{"xs12":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"white","text-transform":"uppercase","letter-spacing":"1px"},style:({
                      'font-size':
                        _vm.$vuetify.breakpoint.name == 'xl' ? '32px' : '28px',
                    })},[_vm._v("About")])]),_c('v-flex',{attrs:{"xs12":"","pt-5":""}},[_c('span',{staticStyle:{"font-family":"poppinsmedium","color":"white","font-size":"20px","letter-spacing":"1px"},style:({
                      'font-size':
                        _vm.$vuetify.breakpoint.name == 'xl' ? '24px' : '20px',
                    })},[_vm._v(" "+_vm._s(_vm.about.title)+" ")])]),_c('v-flex',{attrs:{"xs12":"","sm10":"","md7":"","pt-4":""}},[_c('span',{staticStyle:{"font-family":"poppinsregular","color":"white","font-size":"16px","letter-spacing":"1px"},style:({
                      'font-size':
                        _vm.$vuetify.breakpoint.name == 'xl' ? '20px' : '16px',
                    }),domProps:{"innerHTML":_vm._s(_vm.about.description)}})])],1)],1)],1)],1)],1):_vm._e(),(_vm.about.aboutFounder)?_c('div',{staticClass:"Taill hidden-sm-and-down"},[_c('v-layout',{staticClass:"clash",attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md11":"","lg10":"","py-5":"","align-self-center":""}},[_c('v-card',{staticClass:"boom",attrs:{"elevation":"24"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md8":"","lg8":"","xl10":"","pa-10":"","align-self-center":""}},[(_vm.about.aboutFounder)?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","text-center":""}},[_c('span',{staticStyle:{"font-family":"poppinssemibold","color":"#3cb043","font-size":"14px","text-transform":"uppercase","letter-spacing":"1px"},style:({
                          'font-size':
                            _vm.$vuetify.breakpoint.name == 'xl'
                              ? '18px'
                              : '14px',
                        })},[_vm._v("OUR FOUNDER")])]),_c('v-flex',{attrs:{"xs12":"","pt-2":"","text-center":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"#000000","font-size":"24px","letter-spacing":"1px"},style:({
                          'font-size':
                            _vm.$vuetify.breakpoint.name == 'xl'
                              ? '28px'
                              : '24px',
                        })},[_vm._v(" "+_vm._s(_vm.about.aboutFounder.title)+" ")])]),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","pt-4":"","text-left":""}},[_c('span',{staticStyle:{"font-family":"poppinsregular","color":"#000000","font-size":"14px","letter-spacing":"1px","text-align":"justify"},style:({
                          'font-size':
                            _vm.$vuetify.breakpoint.name == 'xl'
                              ? '18px'
                              : '14px',
                        }),domProps:{"innerHTML":_vm._s(_vm.about.aboutFounder.description)}})])],1):_vm._e()],1),(_vm.about.aboutFounder)?_c('v-flex',{attrs:{"xs12":"","sm10":"","md4":"","lg4":"","xl2":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('v-img',{style:({
                          height:
                            _vm.$vuetify.breakpoint.name == 'xs'
                              ? '100%'
                              : _vm.$vuetify.breakpoint.name == 'sm'
                              ? '100%'
                              : _vm.$vuetify.breakpoint.name == 'md'
                              ? '680px'
                              : _vm.$vuetify.breakpoint.name == 'lg'
                              ? '590px'
                              : '550px',
                        }),attrs:{"src":_vm.mediaURL + _vm.about.aboutFounder.image,"lazy-src":_vm.mediaURL + _vm.about.aboutFounder.image,"alt":_vm.about.aboutFounder.image,"eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1)],1)],1):_vm._e()],1)],1)],1)],1)],1):_vm._e(),_c('div',[_c('v-layout',{staticClass:"hidden-md-and-up",attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","py-5":"","align-self-center":""}},[_c('v-card',{attrs:{"elevation":"24"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md8":"","lg8":"","xl10":"","pa-10":"","align-self-center":""}},[(_vm.about.aboutFounder)?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","text-center":""}},[_c('span',{staticStyle:{"font-family":"poppinssemibold","color":"#3cb043","font-size":"14px","text-transform":"uppercase","letter-spacing":"1px"},style:({
                          'font-size':
                            _vm.$vuetify.breakpoint.name == 'xl'
                              ? '18px'
                              : '14px',
                        })},[_vm._v("OUR FOUNDER")])]),_c('v-flex',{attrs:{"xs12":"","pt-2":"","text-center":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"#000000","font-size":"24px","letter-spacing":"1px"},style:({
                          'font-size':
                            _vm.$vuetify.breakpoint.name == 'xl'
                              ? '28px'
                              : '24px',
                        })},[_vm._v(" "+_vm._s(_vm.about.aboutFounder.title)+" ")])]),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","pt-4":"","text-left":""}},[_c('span',{staticStyle:{"font-family":"poppinsregular","color":"#000000","font-size":"14px","letter-spacing":"1px","text-align":"justify"},style:({
                          'font-size':
                            _vm.$vuetify.breakpoint.name == 'xl'
                              ? '18px'
                              : '14px',
                        }),domProps:{"innerHTML":_vm._s(_vm.about.aboutFounder.description)}})])],1):_vm._e()],1),(_vm.about.aboutFounder)?_c('v-flex',{attrs:{"xs12":"","sm10":"","md4":"","lg4":"","xl2":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('v-img',{style:({
                          height:
                            _vm.$vuetify.breakpoint.name == 'xs'
                              ? '100%'
                              : _vm.$vuetify.breakpoint.name == 'sm'
                              ? '100%'
                              : _vm.$vuetify.breakpoint.name == 'md'
                              ? '680px'
                              : _vm.$vuetify.breakpoint.name == 'lg'
                              ? '100%'
                              : '550px',
                        }),attrs:{"src":_vm.mediaURL + _vm.about.aboutFounder.image},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1)],1)],1):_vm._e()],1)],1)],1)],1),_c('Section2',{attrs:{"storage":_vm.about}}),_c('Section3',{attrs:{"storage":_vm.about}})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }